<template>
    <div class="section page-product container mt-4">
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                    <div class="tile is-child box">
                        <div class="columns">
                            <div class="column is-full">
                                <figure class="image mb-6">
                                    <img v-bind:src="product.get_image">
                                </figure>
                                <h1 class="is-size-4 has-text-left has-text-grey">Plan {{ product.name }}</h1>
                                <p class="has-text-left has-text-grey has-text-weight-bold">KEY SPECS</p>
                                <table class="table is-fullwdith">
                                    <tr>
                                        <td class="p-4 is-size-6">
                                            <span><img loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1716201334/vy9rb30gf0g1ugjep9bd.png" alt="square_meter"></span>
                                            <p>{{ product.floor_area }} sq m</p>
                                        </td>
                                        <td class="p-4 is-size-6">
                                            <span><img loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1716201334/ionqnuhjcpbgbnccrybl.png" alt="bed_icon"></span>
                                            <p>{{ product.bed_rooms }} Beds</p>
                                        </td>
                                        <td class="p-4 is-size-6">
                                            <span><img loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1716201334/djwqeshxttz0zp9hz5lp.png" alt="bath_icon"></span>
                                            <p>{{ product.bath_rooms }} Baths</p>
                                        </td>
                                        <td class="p-4 is-size-6">
                                            <span><img loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1716201334/cgqdldgad922fa9zxcys.png" alt="house_icon"></span>
                                            <p>{{ product.floors }} Floors</p>
                                        </td>
                                    </tr>
                                </table>

                                <div class="box has-text-left" style="background-color: #F2F2F2">
                                    <p class="has-text-weight-bold">This plan can be customized!</p>
                                    <p >Tell us about your desired changes so we can prepare an estimate for the design service. Call +256 772 435 763 </p>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="tile is-6 is-vertical is-parent">    
                <div class="tile is-child box mb-2">
                    <div class="columns is-multiline is-hidden-touch">
                        <div class="box" style="width: 200px; height:150px" v-for="image in product_images" :key="image">
                            <img :src="image.get_image" @click="setMainImage(image.get_image)" alt="Plan Image">
                        </div>
                    </div>
                    <div class="images" v-viewer>
                        <img v-for="src in images" :key="src"  :src="src.get_image">
                    </div>
                    <button type="button" class="button is-info is-uppercase" @click="show">View Photos</button>
                </div>
                <div class="tile is-child box" style="background-color: #EBEBEB">
                    <div class="columns">
                        <div class="column is-full">

                            <p class="has-text-left pt-3 has-text-weight-bold">Select Plan Set Options</p>
                            <div class="select is-primary is-fullwidth">
                            <select>
                                <option>PDF Set - UGX {{ parseInt(product.price).toLocaleString() }}</option>
                            </select>
                            </div>
                            
                            <!--

                                <div class="is-fullwidth pt-2">
                                    <div class="dropdown is-fullwidth">
                                        <div class="dropdown-trigger">
                                            <button class="button is-fullwidth" aria-haspopup="true" aria-controls="dropdown-menu">
                                                <span class="has-text-left">Optional Add-Ons</span>
                                                <span class="icon is-small has-text-right">
                                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                                </span>
                                            </button>
                                        </div>
                                        <div class="dropdown-menu is-fullwidth" id="dropdown-menu" role="menu">
                                            <div class="dropdown-content">
                                                <div class="columns is-mobile dropdown-item" style="display:flex">
                                                    <div class="column is-3">
                                                        <input type="checkbox">
                                                    </div>
                                                    <div class="column is-9">
                                                        Structural drawings  UGX 500,000
                                                    </div>
                                                </div>
                                                <hr class="dropdown-divider pt-0">
                                                <div class="columns is-mobile dropdown-item" style="display:flex">
                                                    <div class="column is-3">
                                                        <input type="checkbox">
                                                    </div>
                                                    <div class="column is-9">
                                                        Eletrical drawings  UGX 300,000
                                                    </div>
                                                </div>
                                                <hr class="dropdown-divider pt-0">
                                                <div class="columns is-mobile dropdown-item" style="display:flex">
                                                    <div class="column is-3">
                                                        <input type="checkbox">
                                                    </div>
                                                    <div class="column is-9">
                                                        Site survey  UGX 500,000 
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            -->



                            <p class="pt-3 has-text-weight-bold">SUB TOTAL    UGX {{ parseInt(product.price).toLocaleString() }}</p>
                            <router-link to="/cart">
                                
                                <a class="button is-warning is-uppercase is-fullwidth" @click="addToCart()">
                                    Add to Cart</a>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-6">

                <div class="mt-3">
                    <h3 class="is-size-4 is-uppercase has-text-left">what is included in this plan set</h3>
                    <hr style="margin-top: 2px; background-color: #B2B2B2;">
                    <div class="list">
                        <ol class="has-text-left">
                            <li class="pt-2">Cover Sheet: Showing architectural rendering of residence</li>
                            <li class="pt-2">Exterior Elevations: In general, each house plan set includes all applicable front, sides and rear elevations, as well as any special exterior details.</li>
                            <li class="pt-2">Interior Elevations: Where applicable, each house plan set includes relevant interior elevations, including cabinets, cabinet details, columns and walls with unique conditions.</li>
                        </ol>
                    </div>
                </div>

                <div class="mt-3">
                    <h3 class="is-size-4 is-uppercase has-text-left mt-3">pricing</h3>
                    <hr style="margin-top: 2px; background-color: #B2B2B2;">
                    <p class="has-text-left">Plan Options</p>
                    <table class="table has-text-left">
                        <th></th>
                        <th></th>
                        <th></th>
                        <tr>
                            <td>5 copy set</td>
                            <td>UGX {{ parseInt(0.9 * product.price).toLocaleString() }}</td>
                            <td class="is-italic">5 printed plan sets mailed to you.</td>
                        </tr>
                        <tr>
                            <td>8 copy set</td>
                            <td>UGX {{ parseInt(0.96 * product.price).toLocaleString() }}</td>
                            <td class="is-italic">8 printed plan sets mailed to you.</td>
                        </tr>
                        <tr>
                            <td>PDF Set BEST VALUE!</td>
                            <td>UGX {{ parseInt(product.price).toLocaleString() }}</td>
                            <td class="is-italic">PDF plan sets are best for fast electronic delivery and inexpensive local printing.</td>
                        </tr>
                        <tr>
                            <td>Reproducible Set</td>
                            <td>UGX {{ parseInt(product.price).toLocaleString() }}</td>
                            <td class="is-italic">For inexpensive local printing / making minor adjustments by hand. 1 printed set, typically on Bond paper.</td>
                        </tr>
                        <tr>
                            <td>CAD Set</td>
                            <td>UGX {{ parseInt(1.6 * product.price).toLocaleString() }}</td>
                            <td class="is-italic">For inexpensive local printing / making minor adjustments by hand. 1 printed set, typically on Bond paper.</td>
                        </tr>
                    </table>


                    <p class="has-text-left">Additional Options</p>
                    <table class="table has-text-left">
                        <th></th>
                        <th></th>
                        <tr>
                            <td>Structural drawings</td>
                            <td class="is-italic">Cost depends on architectural plan</td>
                        </tr>
                        <tr>
                            <td>Preliminary Site conditions</td>
                            <td class="is-italic">Cost can varies with site location</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="column is-6">
                <div class="box" style="background-color: #EBEBEB">
                    <h3 class="is-uppercase is-size-4">Get Personalized Help</h3>
                    <input type="text" class="input mt-2" name="" id="" placeholder="First Name">
                    <input type="text" class="input mt-2" name="" id="" placeholder="Last Name">
                    <input type="text" class="input mt-2" name="" id="" placeholder="Email">
                    <input type="text" class="input mt-2" name="" id="" placeholder="Phone">


                    <p class="has-text-left pt-3">When do you want to start construction?</p>
                    <div class="select is-primary is-fullwidth">
                    <select>
                        <option>-Select-</option>
                        <option>0-3 months</option>
                        <option>3-6 months</option>
                        <option>6-12 months</option>
                        <option>More than one year</option>
                    </select>
                    </div>
                    <p class="has-text-left pt-3">When do you want to start construction?</p>
                    <div class="select is-primary is-fullwidth">
                    <select>
                        <option>-Select-</option>
                        <option>0-3 months</option>
                        <option>3-6 months</option>
                        <option>6-12 months</option>
                        <option>More than one year</option>
                    </select>
                    </div>
                    <p class="has-text-left pt-3">Do you have a lot?</p>
                    <div class="select is-primary is-fullwidth">
                    <select>
                        <option>-Select-</option>
                        <option>No</option>
                        <option>Yes</option>
                        <option>In process</option>
                        <option>Multiple Lots</option>
                    </select>
                    </div>
                    <p class="has-text-left pt-3">Are you working with a builder?</p>
                    <div class="select is-primary is-fullwidth">
                    <select>
                        <option>-Select-</option>
                        <option>No</option>
                        <option>Yes</option>
                        <option>Owner / Builder</option>
                    </select>
                    </div>
                    <p class="has-text-left pt-3">Where are you planning on building?</p>
                    <div class="select is-primary is-fullwidth">
                    <select>
                        <option>-Select-</option>
                        <option>Kampala</option>
                        <option>Greater Kampala</option>
                        <option>Other</option>
                    </select>
                    </div>

                    <div class="pt-3">
                        <textarea class="textarea" placeholder="Enter your questions here"></textarea>
                    </div>

                    <div class="has-text-left">
                        <button class="button is-success is-fullwidth mt-3">Send Message</button>
                        <p class="pt-2">Phone: +256 772 435 763</p>
                        <p class="pt-2">Hours: Mon - Sat: 8.00 - 5.00 pm (EAT)</p>
                        
                    </div>
                    <!--

                        <p>When do you want to start construction?</p>
                        <div class="dropdown is-active">
                            <div class="dropdown-trigger">
                                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                                    <span>Dropdown button</span>
                                    <span class="icon is-small">
                                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                <div class="dropdown-content">
                                    <a href="#" class="dropdown-item">
                                        Dropdown item
                                    </a>
                                    <a class="dropdown-item">
                                        Other dropdown item
                                    </a>
                                    <a href="#" class="dropdown-item is-active">
                                        Active dropdown item
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        Other dropdown item
                                    </a>
                                    <hr class="dropdown-divider">
                                    <a href="#" class="dropdown-item">
                                        With a divider
                                    </a>
                                </div>
                            </div>
                        </div>          
                    -->
                </div>
            </div>
        </div>
    </div>
   
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import 'viewerjs/dist/viewer.css'

export default {
    name: 'Product',
    data() {
        return {
            product: {},
            product_images:[],
            quantity: 1,
            images:[],
            selected_structural: false,
            selected_electrical: false,
            selected_survey: false
        }
    },
    mounted() {

        /*
        var dropdown = document.querySelector('.dropdown');
        dropdown.addEventListener('click', function(event) {
        event.stopPropagation();
        dropdown.classList.toggle('is-active');
        }); */

        this.getProduct()
        this.getProductImages()

    },
    methods: {
        async getProduct(){
            this.$store.commit('setIsLoading', true)

            const category_slug = this.$route.params.category_slug
            const product_slug = this.$route.params.product_slug

            await axios
                .get(`/api/v1/plans/${category_slug}/${product_slug}`)
                .then(response => {
                    this.product = response.data
                    this.product_images.push(this.product)

                    document.title = this.product.name + ' | Tofali Plans'
                })
                .catch(error => {
                    console.log(error)
                })
            
            this.$store.commit('setIsLoading', false)
        },
        async getProductImages(){
            this.$store.commit('setIsLoading', true)

            const product_slug = this.$route.params.product_slug

            await axios
                .get(`/api/v1/plans/images/${product_slug}`)
                .then(response => {
                    this.product_images = response.data
                    
                    
                    for (var key in this.product_images){
                        if (this.product_images.hasOwnProperty(key)) {
                            var item = this.product_images[key];
                            this.images.push(item.get_image);
                        }
                    }

                    ///console.log(this.images)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        addToCart() {
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1
            }

            const item = {
                product: this.product,
                quantity: this.quantity
            }

            this.$store.commit('addToCart', item)

            toast({
                message: 'The plan was added to the cart',
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
            })
        },
        show(){
            this.$viewerApi({
            images: this.images
        })   
      },
      setMainImage(image){
      this.product.get_image = image;
    },
    }
}
</script>


<style scoped>

.dropdown.is-fullwidth {
      display: flex;
   }

   .dropdown.is-fullwidth * {
       width: 100%;
    }

</style>
