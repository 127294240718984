<template>
  <nav class="navbar is-dark is-fixed-top " role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <router-link to="/" class="navbar-item">
    
        <img src="/Tofali-word-logo.png" width="112" height="28">
    </router-link>

    <a role="button" class="navbar-burger" v-on:click="showNav = !showNav" v-bind:class="{ 'is-active' : showNav }" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': showNav }">
    <div class="navbar-end ">
      
      <router-link to="/" class="navbar-item" >
          Home
        </router-link>



        <router-link to="/plans-sort" class="navbar-item">
          Filter
        </router-link>

        <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          Styles
        </a>

        <div class="navbar-dropdown">
          <router-link to="/bungalows" class="navbar-item">
            Bungalow
          </router-link>
          <hr class="navbar-divider">
          <router-link to="/villas" class="navbar-item">
            Modern
          </router-link>
          <hr class="navbar-divider">
          <router-link to="/apartments" class="navbar-item">
            Apartment
          </router-link>
          <hr class="navbar-divider">
          <router-link to="/mansions" class="navbar-item">
            Mansions
          </router-link>
          <hr class="navbar-divider">
          <router-link to="/commercial-business" class="navbar-item">
            Commercial Business
          </router-link>
        </div>
        </div>

        <router-link to="/favorites" class="navbar-item">
          Favorites
        </router-link>

        <router-link to="/orders" class="navbar-item">
          Orders
        </router-link>

    
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons" v-if="!$store.state.isAuthenticated">
          <router-link to="/sign-up" class="button is-primary">
              <strong>Sign up</strong>
          </router-link>
          <router-link to="/log-in" class="button is-">
              Log in
          </router-link>
        </div>
        <div v-else>
          <div class="buttons">
            <a class="button is-success" @click="logout()">Log Out</a>
            <router-link to="/orders">
              <a class="button is-light"><span style="padding-right: 5px;"><img loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1716199744/oyl31hwzemr4dldl6a2g.png" /></span>Logged in as {{ $store.state.username }}</a>
            </router-link>
          </div>
        </div>
      </div>

        <router-link to="/cart" class="navbar-item">
          <span :class="{'is-active': showNav}" style="margin-right: 10px"><img loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1716097366/wwkwiaxkwlzjehkqrnhw.png"></span>
          <span v-if="showNav" style="margin-right: 10px"><img loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1716096912/ywfnelxjntwubgvztmpb.png"></span>
                <span class="is-size-5">{{ cartTotalLength }}</span>
        </router-link>

    </div>
  </div>
</nav>

  <div class="is-loading-bar has-text-centered" v-bind:class="{'is-loading': $store.state.isLoading }">
      <div class="lds-dual-ring"></div>
  </div>
  <router-view/>
  <footer class="footer ">
        <div class="content has-text-centered container">
            <div class="columns">
                <div class="column is-one-third">
                    <p class="is-uppercase">About</p>
                    <p>Order your architectural plans from Tofali Plans, obtain bills of quantities at an affordable cost,
                      talk to industry experts, for modifications to fit for your needs.
                    </p>
                </div>
                <div class="column is-one-third ">
                    <p class="is-uppercase">Contact Us</p>
                    <ul class="menu-links" style="list-style-type: none;">
                  <li class="">
                    ECO CONCRETE LIMITED
                    <br />
                    Plot. No. 39, Naguru drive;
                  </li>
                  <li>P.O. Box 37, Ntinda - Kampala</li>
                  <li>+256 393240090</li>
                  <li>
                    Email: admin@ecoconcrete.co.ug
                  </li>
                </ul>
                </div>
                <div class="column is-one-third">
                    <p class="is-uppercase">Socials</p>
                    <div class="columns is-12 is-mobile">
                    <div class="column is-4" >
                      <span class="icon mt-3"><a href="https://twitter.com/TofaliAfrica"><img loading="lazy" alt="twitter_icon" src="https://res.cloudinary.com/celestialmk/image/upload/v1716100855/dorrg0zaoofrgdxvj2ou.png"></a></span>
                      
                    </div>
                    <div class="column is-4">
                      <span class="icon mt-3"><a href="https://www.facebook.com/profile.php?id=100093655031029"><img loading="lazy" alt="face_book_icon" src="https://res.cloudinary.com/celestialmk/image/upload/v1716101509/k3elr3ujljzw51ce14m3.png"></a></span>
                    </div>
                    <div class="column is-4">
                      <span class="icon mt-3"><a href="https://instagram.com/tofali_africa?igshid=OGQ5ZDc2ODk2ZA=="><img laoding="lazy" alt="instagram_icon" src="https://res.cloudinary.com/celestialmk/image/upload/v1716101980/lai7yvfbsjj7nsbpzwu7.png"></a></span>
                    </div>
                  </div>
                </div>
            </div>
            <p>
            <strong>Tofali Plans</strong> 
            </p>
        </div>
        
    </footer>
</template>


<script>
import Navbar from '@/components/Navbar.vue'
import Layoutbar from '@/components/Layoutbar.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
import update from './mixins/update'


export default {
  components:{
    Navbar,
    Layoutbar,
    Footer
  },
  data() {
    return {
      showMobileMenu: false,
      stored_plans: [],
      cart: {
        items: []
      },
      showNav: false
    }
  },
  components:{
  },
  beforeCreate() {
    this.$store.commit('initializeStore')

    const access = this.$store.state.access

    if( access ){
      axios.defaults.headers.common['Authorization'] = "JWT " + access
    }else{
      axios.defaults.headers.common['Authorization'] = '' 
    }

  },
  mounted(){
    this.cart = this.$store.state.cart

    this.stored_plans = this.$store.state.stored_plans

  },
  mixins: [update],
  computed: {
      cartTotalLength() {
          let totalLength = 0

          for (let i = 0; i < this.cart.items.length; i++) {
              totalLength += this.cart.items[i].quantity
          }

          return totalLength
      },
      
  },
  methods:{
      logout() {
        axios.defaults.headers.common["Authorization"] = "";

        
        //localStorage.removeItem("userid")
        this.$store.commit('')
        this.$store.commit("removeAccess");

        this.$router.push("/");
    }
  }
}
</script>
<style lang="scss">
@import '../node_modules/bulma';

//@import '~@creativebulma/bulma-collapsible';

#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


.navbar-brand {
  min-height: 3.25rem;
  display: flex;
}

nav {
  a.router-link-active {
      color: #42b983;
    }
  }

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}


.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.is-loading-bar {
  height: 0;
  overflow: hidden;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &.is-loading {
    height: 80px;
    margin-top: 100px
  }
}
</style>
